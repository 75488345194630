<template>
    <transition :name="transition" appear>
        <div class="rin-modal-wrapper" :class="{'rin-modal-wrapper-over-bottom-nav' : size === 'prompt-bottom'}" v-if="show" v-show="!isHidden" ref="rin-modal" :style="applyContainerStyles">
            <div class="rin-modal" :class="`rin-modal-size-${size} rin-modal-${type}`" role="document">
                <div class="rin-modal-content" v-click-outside="clickedOutside">
                    <div class="rin-modal-header">
                        <slot name="header"></slot>
                        <BaseIcon v-if="showCloseIcon" name="close" @click.stop.native="$emit('close')"></BaseIcon>
                    </div>
                    <div class="rin-modal-wrapper-body">
                        <slot name="body"></slot>
                    </div>
                    <div class="rin-modal-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import vClickOutside from 'v-click-outside';

    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';

    const modals = { count: 0 };

    export default {
        name: 'BaseModalWrapper',
        directives: {
            clickOutside: vClickOutside.directive,
        },
        components: {
            BaseIcon,
        },
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            isHidden: {
                type: Boolean,
                default: false,
            },
            transition: {
                type: String,
                default: 'translate-fade',
            },
            size: {
                type: String,
                validator: (v) => ['s', 'm', 'l', 'fullv-s', 'fullv-l', 'prompt', 'prompt-bottom'].indexOf(v) > -1,
                default: 'm',
            },
            type: {
                type: String,
                validator: (v) => ['primary', 'secondary', 'tertiary'].indexOf(v) > -1,
                default: 'secondary',
            },
            backdrop: {
                type: Boolean,
                default: true
            },
            scrollableBackground: {
                type: Boolean,
                default: false
            },
            disableCloseOnClickOutside: {
                type: Boolean,
                default: false
            },
            showCloseIcon: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                zIndex: 0,
                backdropEl: null,
                modals,
            };
        },
        computed: {
            applyContainerStyles() {
                const styles = {};
                if (this.show) {
                    styles.display = 'block';
                }

                styles['z-index'] = 2000 + this.zIndex;
                return styles;
            },
        },
        methods: {
            clickedOutside() {
                if (this.disableCloseOnClickOutside) return;

                if (this.backdrop)
                    this.$emit('close', {isClickedOutside: true});
            },
            updateBackdrop() {
                if (this.show) {
                    this.backdropEl = document.createElement('div');
                    this.backdropEl.classList.add('rin-modal-backdrop');
                    this.backdropEl.style.zIndex = 1000 + this.zIndex;
                    document.body.appendChild(this.backdropEl);
                } else {
                    if (this.backdropEl) {
                        document.body.removeChild(this.backdropEl);
                    }
                }
            },
        },
        mounted() {
            if (this.show) {
                this.modals.count++;
                this.zIndex = this.modals.count;
                if (!this.scrollableBackground) document.body.classList.add('rin-body-modal-open');
                this.$emit('show', true, this.zIndex, this.modals.count);
                this.updateBackdrop();
            }
        },
        destroyed() {
            if (this.show) {
                this.modals.count--;
                this.zIndex = this.modals.count;
                this.$emit('show', false, this.zIndex, this.modals.count);
            }

            if (this.modals.count === 0) {
                document.body.classList.remove('rin-body-modal-open');
            }

			try {
				document.body.removeChild(this.backdropEl);
			} catch (e) {
				return;
			}
        },
        watch: {
            show(value) {
                if (value) {
                    this.modals.count++;
                    if (!this.scrollableBackground) document.body.classList.add('rin-body-modal-open');
                } else {
                    this.modals.count--;
                }

                this.zIndex = this.modals.count;
                this.$emit('show', value, this.zIndex, this.modals.count);
                if (!value && this.modals.count === 0) {
                    document.body.classList.remove('rin-body-modal-open');
                }

                this.updateBackdrop();
            },
        },
    };
</script>
