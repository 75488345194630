export default [
    {
        isMobile: false,
        isDesktop: true,
        isTestAd: true,
        display() {
            window.googletag.cmd.push(function () {

                window.googletag
                    .defineSlot('/6355419/Travel/Europe', [[728, 90]], 'banner-ad')
                    .addService(window.googletag.pubads());

                window.googletag.display('banner-ad');

            });
        }
    },
    {
        isMobile: true,
        isDesktop: false,
        isTestAd: true,
        display() {
            window.googletag.cmd.push(function () {

                window.googletag
                    .defineSlot('/6355419/Travel/Europe', [[728, 90]], 'anchor-ad')
                    .addService(window.googletag.pubads());

                window.googletag.display('anchor-ad');

            });
        }
    },

    // prod ads
    {
        isMobile: false,
        isDesktop: true,
        isTestAd: false,
        display() {
            window.googletag.cmd.push(function () {
                window.googletag
                    .defineSlot('/22839279915/desktop-banner-add', [[970, 90]], 'banner-ad')
                    .addService(window.googletag.pubads());

                window.googletag.display('banner-ad');
            })
        }
    },
    {
        isMobile: true,
        isDesktop: false,
        isTestAd: false,
        display() {
            window.googletag.cmd.push(function () {
                window.googletag
                    .defineSlot('/22839279915/desktop-banner-add', [[300, 50], [970, 66]], 'anchor-ad')
                    .addService(window.googletag.pubads());

                window.googletag.display('anchor-ad');
            })
        }
    }
] 