<template>
    <div id="body-content-main" v-bind:class="{offset: showHeader, 'rin-main-content-mobile-menu-opened' : isSearchShown || isMenuShown, 'rin-admin-part': $route.meta.isAdminPart, 'rin-page-navbar-visible': isPageNavBarVisible, 'rin-alternative-bg': isAlternativeBackgroud}" style="display: flex; flex-flow: column nowrap; min-height: 100vh;">
        <header v-if="showHeader" :class="{'rin-search-open' : isSearchShown || isMenuShown}">
            <use-google-chrome-bar v-if="!layout.isEmbed"></use-google-chrome-bar>
            <OldStoreAppWarningTopBar v-if="layout.isOldStoreApp"></OldStoreAppWarningTopBar>
            <NoWorriesTopBar v-if="!layout.isEmbed && layout.player.isAuthenticated && !layout.player.IsProPlayer"></NoWorriesTopBar>
            <LogosTopBar v-if="!layout.isEmbed && layout.player.isAuthenticated && layout.player.IsProPlayer"></LogosTopBar>
            <!--<PageTopBar v-if="!layout.isEmbed && layout.player.isAuthenticated && !layout.player.IsProPlayer" />-->
            <Transition name="slide">
                <TopNav v-if="!layout.isEmbed && showNavbar" :show-back-arrow="scrolledPassHeader"></TopNav>
            </Transition>
            <portal-target name="page-navbar"></portal-target>
        </header>
        <div v-show="isTopNotificationShown" class="wrap margin-fix-afterheader" :class="{'rin-search-open' : isSearchShown || isMenuShown}">
            <div role="alert" class="alert alert-top-notification">
                {{topNotificationMessage}}
                <button type="button" class="close" aria-label="Close" v-on:click="closeTopNotification">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <main class="rin-main-content" v-bind:class="{'authenticated': layout.player.isAuthenticated && showHeader, 'notauthenticated': !layout.player.isAuthenticated && showHeader, 'ads-offset': isAdVisible && $windowSize.isMobile}">
            <Search v-if="isSearchShown"></Search>
            <NavMenu v-if="isMenuShown"></NavMenu>
            <div id="landingPageContainer" class="container-search" :class="{'rin-search-open' : isSearchShown || isMenuShown}">
                <div v-if="isAdVisible && !$windowSize.isMobile" id="banner-ad" v-bind:class="currentFeature">
                    <DesktopAdBanner :key="bottomAdBannerKey"></DesktopAdBanner>
                </div>
                <div v-if="isAdVisible && $windowSize.isMobile" id="anchor-ad">
                    <BottomAdBanner :key="bottomAdBannerKey"></BottomAdBanner>
                </div>
                <!--this portal is a workaround due to the "getPathKey" method bound to the key of the router view below.
            this portal prevents the reloading of the whole chat component.
            it works for clubleague and tournament because the key doesnt change inbetween changing nested routes, but it does for the chat component
            this should be removed at some point and the getPathKey method fixed or removed entirely
            Nick said getPathkey was to fix an issue with changing language, but this issue might have been fixed alltogether since then-->
                <portal-target name="messages"></portal-target>
                <!--------->
                <router-view :key="getPathKey"></router-view>
            </div>
            <portal-target name="main-body-modal"></portal-target>
            <portal-target name="main-base-modal"></portal-target>
        </main>
        <BottomNav v-if="!layout.isEmbed && $windowSize.isMobile && showFooter"></BottomNav>
        <component v-bind:is="adsComponentName"></component>
        <layoutFooter v-if="!layout.isEmbed && showFooter" :class="{'rin-search-open' : isSearchShown || isMenuShown}"></layoutFooter>
        <DesktopFooter v-if="!layout.isEmbed && showFooter" />
        <head2head-modal></head2head-modal>
        <spinner-modal></spinner-modal>
        <BaseModalWrapper size="l" :show="isMonetizationModalOpen" @close="hideProPlayersModal">
            <template #body>
                <ModalProPlayers />
            </template>
        </BaseModalWrapper>
        <BaseModalWrapper :show="showFreeTrialModal" @close="hideFreeTrialModal" :backdrop="false">
            <template #body>
                <TrialModal @trialStarted="updateIsFreeTrialStarted(true)" @close="hideFreeTrialModal" />
            </template>
        </BaseModalWrapper>
        <BaseModalWrapper :show="isBootboxShown" @close="rinBootbox.close" style="z-index: 10000000000">
            <template #header>
                <h3>{{rinBootboxTitle}}</h3>
            </template>
            <template #body>
                {{rinBootboxBody}}
                <span v-html="rinBootboxBodyHtml"></span>
            </template>
            <template #footer>
                <BaseButton v-if="rinBootboxSuccessBtn" type="primary" size="m" @click.native="rinBootbox.success">
                    {{ rinBootboxSuccessBtnText }}
                </BaseButton>
                <BaseButton type="secondary" size="m" @click.native="rinBootbox.close">
                    {{translations['shared_CloseButton']}}
                </BaseButton>
            </template>
        </BaseModalWrapper>
        <RegisterIndex />

        <div data-fuse-privacy-tool></div>
    </div>
</template>

<script>
    import spaEventBus from "@/app/eventBus";
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import { GET_LAYOUT_MODEL_ASYNC } from "@/store/modules/layout-module.js";

    import LogosTopBar from '@/components/layout/top-bar/logos-top-bar-bs4';
    import NoWorriesTopBar from '@/components/rin_new_design/components/page-top-bar/NoWorriesTopBar';
    import OldStoreAppWarningTopBar from '@/components/rin_new_design/components/page-top-bar/OldStoreAppWarningTopBar';
    import useGoogleChromeBar from "@/components/shared/use-google-chrome-bar.vue";
    import searchResults from '@/components/layout/search/search-results-bs4';
    import layoutFooter from '@/components/layout/footer/layout-footer-bs4';
    import DesktopFooter from '@/components/layout/footer/DesktopFooter'
    import topBarNotLogged from '@/components/layout/top-bar/top-bar-partial-notlogged-bs4'
    import head2headModal from "@/components/shared/head2head-modal-bs4.vue";
    import spinnerModal from "@/components/shared/spinner-modal-bs4.vue";

    import TopNav from '@/components/rin_new_design/components/nav/TopNav.vue';
    import BottomNav from '@/components/rin_new_design/components/nav/BottomNav.vue';
    import Search from '@/components/rin_new_design/components/search/Search.vue';
    import NavMenu from '@/components/rin_new_design/components/nav/NavMenu.vue';
    import PageTopBar from '@/components/rin_new_design/components/page-top-bar/PageTopBar';
    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
    import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
    import ModalProPlayers from '@/components/rin_new_design/components/monetization/modals/ModalProPlayers';
    import RegisterIndex from '@/components/rin_new_design/components/register/RegisterIndex';
    import TrialModal from '@/components/rin_new_design/components/monetization/modals/TrialModal';
    import BottomAdBanner from '@/components/rin_new_design/components/GoogleAds/BottomBanner';
    import DesktopAdBanner from '@/components/rin_new_design/components/GoogleAds/DesktopBanner';


    import rinBootbox from '@/app/rin-bootbox';
    import rinbootboxModule from "../../store/modules/rinbootbox-module";


    export default {
        name: "mainApp",
        metaInfo() {
            return {
                colorLocal: '',
                title: this.layout.meta.title,
                titleTemplate: '%s | Rankedin',
                link: this.layout.hreflangsAndCanonicalLinks,
                meta: [
                    { charset: 'utf-8' },
                    { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
                    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge, chrome=1' },
                    { name: 'format-detection', content: 'telephone=yes' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0' },
                    { name: 'author', content: 'rankedin.com' },
                    { property: 'fb:app_id', content: '575210812498503' },
                    { property: 'og:site_name', content: 'Rankedin' },

                    { name: 'description', content: this.layout.meta.description },

                    { property: 'og:type', content: 'article' },
                    { property: 'og:title', content: this.layout.meta.socialMediaTitle },
                    { property: 'og:url', content: this.layout.meta.socialMediaUrl },
                    { property: 'og:description', content: this.layout.meta.socialMediaDescription },
                    { property: 'og:image', content: this.layout.meta.socialMediaImage },
                ]
            }
        },
        components:
        {
            LogosTopBar,
            NoWorriesTopBar,
            'use-google-chrome-bar': useGoogleChromeBar,
            'searchResults': searchResults,
            'layoutFooter': layoutFooter,
            'topBarNotLogged': topBarNotLogged,
            'head2head-modal': head2headModal,
            'spinner-modal': spinnerModal,
            TopNav,
            BottomNav,
            Search,
            NavMenu,
            DesktopFooter,
            PageTopBar,
            BaseModalWrapper,
            BaseButton,
            ModalProPlayers,
            RegisterIndex,
            TrialModal,
            DesktopAdBanner,
            BottomAdBanner,
            OldStoreAppWarningTopBar
        },
        beforeMount() {
            this.$store.dispatch("updateLayoutDomain", window.location.origin);
            this.displaySupportChat();
        },
        data() {
            return {
                windowWidth: 0,
                scrolledPassHeader: false,
                adsComponentName: '',
                rinBootbox: rinBootbox,

                bottomAdBannerKey: 1,
                currentFeature: '',

                showNavbar: true,
                lastScrollPosition: 0
            }
        },
        created() {
            if (this.$windowSize.isMobile) {
                window.addEventListener('scroll', this.navbarVisibilityHandler)
            }
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.navbarVisibilityHandler)
            window.removeEventListener('scroll', this.handleScroll);
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('monetization', ['isProPlayer', 'showFreeTrialModal', 'isFreeTrialStarted', 'shouldShowAds', 'isTournamentKillSwitchActive']),
            ...mapGetters('ui', ['isPageNavBarVisible', 'isMonetizationModalOpen', 'isAlternativeBackgroud']),

            isAdVisible() {
                return this.shouldShowAds && !this.isTournamentKillSwitchActive;
            },
            offsetSearchResults() {
                return this.$route.name === 'landing-page';
            },
            isTopNotificationShown: function () {
                return this.layout.topNotification.isShown;
            },
            topNotificationMessage: function () {
                return this.layout.topNotification.message;
            },
            showHeader() { return this.$store.state.layout.showHeader; },
            showFooter() { return this.$store.state.layout.showFooter; },
            isSearchShown() {
                return this.$store.getters['search/isShown'];
            },
            isMenuShown() {
                return this.$store.getters['topnav/isMenuOpen'];
            },
            isIOSorAndroidUserDeviceRin: function () {
                return this.nomenclatures.UserDeviceRin == 'ios' || this.nomenclatures.UserDeviceRin == 'android';
            },
            getPathKey() {
                if (this.$route.path.split('/').length < 3) return this.$route.path;
                let key = this.$route.path.split('/')[1] + this.$route.path.split('/')[2];
                if (typeof (this.$route.params.id) !== 'undefined') {
                    key += this.$route.params.id;
                }

                return key;
            },
            isBootboxShown() {
                return this.$store.getters['rinBootbox/isShown'];
            },
            rinBootboxTitle() {
                return this.$store.getters['rinBootbox/title'];
            },
            rinBootboxBody() {
                return this.$store.getters['rinBootbox/body'];
            },
            rinBootboxBodyHtml() {
                return this.$store.getters['rinBootbox/bodyhtml'];
            },
            rinBootboxSuccessBtn() {
                return this.$store.getters['rinBootbox/hasSuccessButton'];
            },
            rinBootboxSuccessBtnText() {
                return this.$store.getters['rinBootbox/successText'];
            },

        },
        watch: {
            // call again the method if the route changes
            '$route': function (to, from) {
                this.currentFeature = this.$router.currentRoute?.meta?.feature;

                if (this.layout.isOldStoreApp) rinBootbox.alerthtml("Warning", `This Mobile APP is no longer supported, please use a Chrome Browser. <br><br> Open your browser and enter www.rankedin.com into the address bar.`);

                if (!this.layout.isEmbed) {
                    this.hideBurger();
                }
                else {
                    document.getElementsByTagName('body')[0].classList.add('embed-padding');
                }

                if (to.path != from.path)
                    this.removeFixedBackground();

                let queryParams = _.mapKeys(to.query, (k, v) => v.toLowerCase());

                if (typeof queryParams.isaccountconfirmed != 'undefined') {
                    this.$store.dispatch("layout_updateTopNotification", { isShown: true, message: this.translations['register_AccountActivatedNotification'] });
                }

                if (typeof queryParams.notificationmessage != 'undefined') {
                    switch (queryParams.notificationmessage) {
                        case 'americano_successfulconfirmation':
                            this.$store.dispatch("layout_updateTopNotification", { isShown: true, message: this.translations['americano_SuccessfulConfirmation'] });
                            break;
                        case 'americano_notsuccessfulconfirmation':
                            this.$store.dispatch("layout_updateTopNotification", { isShown: true, message: this.translations['americano_NotSuccessfulConfirmation'] });
                            break;
                        default:
                            console.log(`Sorry, we are out of translations for ${urlParams.get('notificationMessage'.toLowerCase())}.`);
                    }
                }
                this.adsComponentName = '';
            }
        },
        mounted() {

            spaEventBus.$on('refreshLayoutModel', () => {
                this.$store.dispatch(GET_LAYOUT_MODEL_ASYNC);
            });
            spaEventBus.$on('loginOrLogff', () => {
                bindHandlersForOkinetDesign();
                this.bindTopNavigationHandlers();

                if (this.layout.player.isAuthenticated)
                    this.$gtag.event('login', { account: this.layout.player.rankedinId });
            });
            spaEventBus.$on('showModalAd', (componentName) => {
                this.adsComponentName = componentName;
            });
            spaEventBus.$on('showGoogleAd', (shouldShowAds) => {
                this.triggerGoogleAds(shouldShowAds);
            });
            spaEventBus.$on('refreshGoogleAd', () => {
                this.triggerGoogleAds(this.shouldShowAds);
            });

            bindHandlersForOkinetDesign();
            this.bindTopNavigationHandlers();
        },
        methods: {
            ...mapMutations('monetization', ['updateShowFreeTrialModal', 'updateIsFreeTrialStarted']),
            ...mapActions('ui', ['setMonetizationModalState']),
            navbarVisibilityHandler() {
                if (this.layout.player.isAuthenticated) {
                    if (this.isMenuShown) return;
                    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                    if (currentScrollPosition < 0) {
                        return;
                    }
                    if (currentScrollPosition > this.lastScrollPosition && (currentScrollPosition - this.lastScrollPosition) < 45) {
                        return;
                    }
                    this.showNavbar = currentScrollPosition < this.lastScrollPosition;
                    this.lastScrollPosition = currentScrollPosition;
                }
            },
            bindTopNavigationHandlers() {
                $(window).off('scroll');
                $('.profile .pro').off('click');

                if (!this.layout.player.isAuthenticated) {
                    //layout-top-bar-not-logged-in-users.js
                    $('.profile .pro').on('click', function (e) {
                        var profileMenu = $('.profileMenu');
                        var t = $(this);
                        if (!t.hasClass('open')) {
                            profileMenu.slideDown(function () {
                                t.addClass('open');
                            });
                        } else {
                            profileMenu.slideUp(function () {
                                t.removeClass('open');
                            });
                        }
                    });
                }
                else {
                    //layout-top-bar-logged-in-users.js
                    const _this = this;
                    $(window).scroll(function () {
                        if (_this.isMenuShown) return;
                        var header = jQuery('header');

                        var scroll = jQuery(window).scrollTop();

                        if (48 < scroll) {
                            _this.scrolledPassHeader = true;

                            if (!header.hasClass('fixed')) {
                                header.addClass('fixed');
                                jQuery('body').addClass('fixedHeader');
                            }
                        } else {
                            _this.scrolledPassHeader = false;
                            header.removeClass('fixed');
                            jQuery('body').removeClass('fixedHeader');
                        }
                    });
                }
            },
            handleScroll(event) {
                this.closeTopNotification();
            },
            removeFixedBackground() {
                document.getElementsByTagName('body')[0].classList.remove("position-fixed");
            },
            closeTopNotification: function () {
                this.$store.dispatch("layout_updateTopNotification", { isShown: false, message: '' });
            },
            isRouteForbidden() {
                return !!this.$router.currentRoute?.meta?.ads?.isForbidden;
            },
            triggerGoogleAds(shouldShowAds) {
                if (this.shouldShowAds === shouldShowAds && shouldShowAds) {
                    // refresh ads
                    this.bottomAdBannerKey++;
                }
                else {
                    this.$store.commit('monetization/updateShouldShowAds', shouldShowAds);
                }
            },
            displaySupportChat: function () {
                const inIframe = window.self !== window.top;
                if (inIframe) return;
                if (!this.layout.player.isEventOrganizer) return;

                var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
                (function () {
                    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                    s1.async = true;
                    s1.src = 'https://embed.tawk.to/5db2db1778ab74187a5b80f8/default';
                    s1.charset = 'UTF-8';
                    s1.setAttribute('crossorigin', '*');
                    s0.parentNode.insertBefore(s1, s0);
                })();
            },
            hideBurger: function () {
                const menu = document.getElementsByClassName('profileMenu')[0];
                const menuWrapper = document.getElementsByClassName('user')[0];
                const burger = document.getElementsByClassName('burger')[0];
                const mobileMenu = document.getElementsByClassName('menu')[0];
                burger?.classList.remove('open');
                mobileMenu?.removeAttribute('style');
                menu?.classList.remove('open');
                menu?.removeAttribute('style');
                menuWrapper?.classList.remove('open');
            },
            showProPlayersModal() {
                this.setMonetizationModalState(true);
            },
            hideProPlayersModal() {
                this.setMonetizationModalState(false);
            },
            hideFreeTrialModal() {
                if (this.isFreeTrialStarted) {
                    window.location.reload();
                }
                else {
                    this.updateShowFreeTrialModal(false);
                }
            }
        }
    }
</script>
<style>

    .embed-padding {
        padding-top: 20px !important;
    }

    .margin-fix-afterheader {
        margin-top: -12px;
    }

    .alert-top-notification {
        margin-top: 10px;
        color: #2e3e78;
        background-color: #d7f6e5;
        font-weight: 600;
        position: fixed;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
    }

    @media (max-width: 1006px) {
        .alert-top-notification {
            margin-top: 40px;
        }
    }

    #fuse-privacy-tool {
        font-size: 0.5rem;
    }
</style>



<style>
    main.notauthenticated .submenu.fixed {
        top: 0 !important;
    }
</style>
